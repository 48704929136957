<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReportingAnalyticManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<HeaderBack :title="'СВЕДЕНИЯ ПО СУБЪЕКТАМ, ЗАНЯТЫМ ВЫРАЩИВАНИЕМ РЫБЫ'" />

							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="search-main">
												<div class="row">
													<div class="col-md-4">
														<div class="table__filter--search">
															<label class="search__label">
																<input
																	type="text"
																	value=""
																	name="filter_search"
																	v-model="v_model_name_search"
																	placeholder="Поиск по наименованию"
																/>

																<button>
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																</button>
															</label>
														</div>
														<!-----tsble-filter-search------>
													</div>
													<!-----col------>

													<div class="col-md-4">
														<div class="table__filter--search">
															<label class="search__label">
																<input
																	type="text"
																	v-model="v_model_iin_search"
																	placeholder="Поиск по ИИН/БИН"
																/>

																<button>
																	<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																</button>
															</label>
														</div>
														<!-----search------>
													</div>
													<!-----col------>

													<div class="col-md-4">
														<div class="calendar">
															<quarter
																:title="''"
																ref="quarter"
																:placeholder="'Выбрать квартал'"
																:inputYears="true"
																:allOptions="true"
																v-on:update:modelValue="quarter_value = $event"
															>
															</quarter>
														</div>
													</div>
												</div>
												<!-----row------>

												<div class="row mt-3">
													<div class="col-md-4">
														<region
															ref="region"
															:modelValue="v_model_region_search"
															:name="'region_id'"
															v-on:update:modelValue="v_model_region_search = $event"
															:allOptions="false"
															:multiple="multiple"
															:title="''"
															:placeholder="'Поиск по региону'"
														>
														</region>
													</div>
													<!-----col------>

													<div class="col-md-4">
														<pond
															:key="render"
															:multiple="multiple"
															:title="''"
															:required="true"
															:allOptions="false"
															ref="pond"
															:placeholder="'Водоем'"
															v-on:update:modelValue="v_model_pond_search = $event"
														>
														</pond>
													</div>

													<!-----col------>

													<div class="col-md-4">
														<fish
															:required="true"
															:multiple="multiple"
															name="fish"
															:placeholder="$t('placeholder.choose')"
															v-on:update:modelValue="v_model_fish_search = $event"
														></fish>
													</div>
												</div>
												<!-----row------>
											</div>
											<!-----search-main------>
										</div>
									</div>

									<v-data-table
										class="table__analytic"
										:headers="headers"
										:items="filteredlist"
										hide-default-footer
										:loading="false"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<router-link
													:to="
														'/' +
														$i18n.locale +
														'/account/reporting-analytics/' +
														8 +
														`/${item.region_id}`
													"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
											</div>
										</template>

										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api } from '@/boot/axios'

import quarter from '@/components/components/Inputs/quarter'
import Region from '@/components/components/Inputs/region'
import Pond from '@/components/components/Inputs/pond'
import Fish from '@/components/components/Inputs/fish'

import HeaderBack from '@/components/UI/HeaderBack.vue'

export default {
	name: 'ReportManageGoRegion8',
	components: {
		quarter,
		Region,
		Pond,
		Fish,
		HeaderBack,
	},
	data() {
		return {
			pageId: 8,
			analytic: [],
			headers: [
				{
					text: 'Наименование областей',
					value: 'region_name',
					width: '20vw',
					sortable: false,
				},
				// { text: 'Лимит (кг)', value: 'sum_actually_grown_by_species_weight', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_crustaceans_count', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_cultivation_workers_count', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_electricity_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_enclosed_area', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_exported_fish_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_exported_fish_volume', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_feed_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_fish_material_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_fish_processing_workers_count', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_fry_count', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_investment_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_larvae_count', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_mineral_fertilizers_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_primary_activity', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_purchase_of_broodstock_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_purchase_of_medicines_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_rbo_development_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_repair_material_cost', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_repair_material_count', sortable: false },
				// { text: 'Лимит (кг)', value: 'sum_yearlings_count', sortable: false },
				{
					text: 'Рыб и других водных животных (количество)',
					value: 'sum_fish_etc_count',
					sortable: false,
				},
				{
					text: 'План выращивания по видам, кг',
					value: 'sum_growing_plan_by_species',
					sortable: false,
				},
				{
					text: 'Фактически выращено по видам кг / тыс. тенге',
					value: 'sum_actually_grown_by_species_cost',
					sortable: false,
				},
				{
					text: 'Производственная мощность (кг в год)',
					value: 'sum_productive_capacity',
					sortable: false,
				},
				{ text: 'ОТРХ площадь (гектар)', value: 'sum_otrh_area', sortable: false },
				{ text: 'Площадь садков (метр куб)', value: 'sum_cage_area', sortable: false },
				{ text: 'УЗВ (метр куб)', value: 'sum_uzv_area', sortable: false },
				{ text: 'Площадь прудов (гектар)', value: 'sum_pond_area', sortable: false },
				{ text: 'Бассейны (кв. метр)', value: 'sum_pool_area', sortable: false },
				{ text: 'Другие (гектар или метр куб)', value: 'sum_other_area', sortable: false },
			],
			v_model_name_search: [],
			v_model_region_search: [],
			v_model_pond_search: [],
			v_model_iin_search: '',
			multiple: true,
			v_model_fish_search: '',
			render: 0,
			quarter_value: '',
			inputYears: null,
			viewType: 'table',
			applicationsRegion: [],
			applicationsLimit: [],
			modalTo: false,
			modalFrom: false,
			date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
				.toISOString()
				.substr(0, 10),
			filter: {
				limit: null,
				region: null,
				range_to: null,
				range_from: null,
				user: null,
			},

			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},
		}
	},
	computed: {
		filteredlist() {
			return this.analytic
		},
	},
	methods: {
		checkError(error) {
			if (error?.response?.status > 500) {
				this.errorMessage.status = 500
				this.errorMessage.text = this.$t('system_message.500')
			} else if (error?.response?.status == 401) {
				this.errorMessage.status = 401
				this.errorMessage.text = error?.response?.data?.message
				this.$router.push('/' + this.$i18n.locale + '/login')
			} else {
				this.errorMessage.status = 422
				this.errorMessage.text = error?.response?.data?.message
			}
			this.$modal.show('ReportManageErrorModal')
		},
		async apiGetAnalytics() {
			try {
				const response = await api.get(`go/list/${this.pageId}`)
				if (response.data) {
					this.analytic = response.data.data
				}
			} catch (error) {
				this.checkError(error)
			}
		},
	},
	async beforeMount() {
		await this.apiGetAnalytics()
	},
}
</script>

<style>
.reporting-btn .input-btn.form__line--btn {
	background: #52a5fc;
	width: 250px;
	height: 47px;
	padding: 0;
	justify-content: center;
	transition: background 1s ease-in;
}

.checkbox {
	display: flex;
	align-items: center;
	align-content: center;
	padding: 10px 0;
}

.custom-checkbox {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.custom-checkbox + label {
	width: 200px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	color: #0a091d;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-checkbox + label::before {
	content: '';
	display: inline-block;
	width: 17px;
	height: 17px;
	flex-shrink: 0;
	flex-grow: 0;
	border-radius: 6px;
	margin-right: 0.5em;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: 50% 50%;
	border: 1.5px solid #52a5fc;
}

.table__block--filter {
	display: block;
	padding: 30px;
}

.search-main {
	width: 100%;
}

.table__filter--search {
	width: 100%;
	margin: 0;
}

.search__label {
	display: block;
	width: 100%;
}

.table__filter--search label input {
	width: 100%;
}
</style>
